import React from 'react';
import makeStyles from '@material-ui/core/styles/makeStyles';
import AppBar from '@material-ui/core/AppBar';
import Typography from '@material-ui/core/Typography';
import Button from '@material-ui/core/Button';
import Toolbar from '@material-ui/core/Toolbar'
import { Link } from "gatsby"

import '../styles/styles.scss';
import { nominalTypeHack } from 'prop-types';

const useStyles = makeStyles(theme => ({
  navBar: {
    flexGrow: 1,
    background: '#222534',
    height: '60px',
    borderBottom: 0,
    paddingBottom: 70,
    boxShadow: 'none',
    position: '-webkit-sticky',
    position: 'sticky',
    [theme.breakpoints.down('sm')]: {
      height: 170
    },
  
  },
  title: {
    flexGrow: 1,
    color: 'white',
    fontFamily: ["Montserrat", "sans-serif"],
    fontSize: 35,
    marginLeft: 20,
    
    [theme.breakpoints.down('sm')]: {
      marginLeft: 0,
      height: 100
    },
  },
  button: {
    borderRadius: 50,
    borderColor: 'white',
    margin: 5,
    color: 'white',
    fontFamily: ["Montserrat", "cursive"],
    [theme.breakpoints.down('sm')]: {
      margin: 2,
      fontSize: 15,
    },
  },
    navButton: {
      [theme.breakpoints.down('sm')]: {
        width: 140,
        marginTop: 20,
      },
      

  }

 
}));
    
export default function NavBar() {
  const classes = useStyles();

  return (
    <React.Fragment>

    
    <AppBar position="fixed" className={classes.navBar}>
      <Toolbar>

      
      <Typography className={classes.title} variant="h6">
              MalvinNathan
      </Typography>
      <div className={classes.navButton}>
      
      <Button component={Link} to='/' className={classes.button} color="primary"> Home </Button>
      <Button component={Link} to ='/Projects/' className={classes.button} color="primary"> Project </Button>
      <Button component={Link} to='/SayHello/' variant="outlined" className={classes.button} color="primary"> Say Hello</Button>
      </div>

      </Toolbar>
    </AppBar>
    </React.Fragment>

  )
  
}

/*
<Toolbar>
          <IconButton onClick={() => dispatch(toggleDarkMode())} edge='start' className={classes.menuButton} color='inherit'>
            {dark ? <WbSunnyOutlinedIcon /> : <WbSunnyRoundedIcon />}
          </IconButton>
        </Toolbar>*/