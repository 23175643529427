// Gatsby & React stuff
import React, { useState } from 'react';
import { Link } from "gatsby"

//Google Fonts style
import '../styles/styles.scss';

// Image asset
import ifoliologo from '../images/ifolio_logo.png';
import malvinnathan from '../images/malvinnathan.png';
import shopoint from '../images/shopoint.png';
import unityimage from '../images/unity.png';

//Material UI Stuffs
import NavBar from '../components/NavBar';
import Grid from '@material-ui/core/Grid';
import Typography from '@material-ui/core/Typography';
import makeStyles from '@material-ui/core/styles/makeStyles';
import Button from '@material-ui/core/Button';
import {Helmet} from "react-helmet";


const useStyles = makeStyles(theme => ({

    fontstyleaboutme: {
        fontFamily: ["'Short Stack'", "cursive"],
        padding: 10,
        marginTop: 20,
        fontSize: 30,
        textAlign: "center",
        justify:'center',
        alignItems:'center',
        [theme.breakpoints.down('sm')]: {
          marginTop: 110,
        },
    },
    fontstyleaboutme2: {
        fontFamily: ["Arial", "cursive"],
        fontSize: 20,
        textAlign: "center",
        justify:'center',
        alignItems:'center',

    },
    projectgrid: {
        borderRadius: '12px',
       
        position: "relative",
        height: '30vh',
        marginLeft: 50,
        marginRight: 50,
        marginTop :20,
        marginBottom: 20,
        transition: [".25s" ,"ease-in-out"],
    },
    TextField: {
        
        fontSize: 15,
        fontFamily: ['Raleway','Sans Serif'],
        marginLeft : 5,
        color: "black",

    },
    projectitle : { 
        marginTop: 10,
        textAlign : 'center',
        fontWeight: 'bold',
        fontSize: 20,
    },
    image : {
        display: "block",
        position: "relative",
        width: "50%",
        height: "auto%",
    },
    button: {
        borderRadius: 50,
        marginLeft: 20,
        marginRight: 20,
        textAlign: "center",
        display: "block",
        justify:'center',
        alignItems:'center',
        borderColor: 'black',
        color: 'black',
        fontFamily: ["'Short Stack'", "cursive"],
    },

}));

export default function Projects() {
    const classes = useStyles();
    const [present, setPresent] = useState(false);
    const [present1, setPresent1] = useState(false);
    const [present2, setPresent2] = useState(false);
    const [present3, setPresent3] = useState(false);

    


    return (
        <>
        <Helmet>
                <meta charSet="utf-8" />
                <title>MN - Projects</title>
                <link rel="canonical" href="http://malvinnathan.com" />
            </Helmet>
        <NavBar/>

        <Grid container>
            <Grid item  xs={12} sm={12} md={12} lg={12}>
                <Typography className={classes.fontstyleaboutme}  variant='h1'>
                My Recent Projects
                </Typography>
                <Typography className={classes.fontstyleaboutme2}>
                    and many more to come..
                </Typography>
            </Grid>
            
            <Grid onMouseOver={() => setPresent(true)} 
                    onMouseLeave={() => setPresent(false)}
                    style={{backgroundColor: present ? "#d1eaea" : "#d1eaea" }} item xs={12} sm={6} md={3} lg={3} className={classes.projectgrid} >
                
                {present == false &&
                <img src={ifoliologo} style={{
                        width: "5em",
                        height: "auto",
                        display: "block",
                        margin: "auto",
                        marginTop: "3em",
                        marginBottom : "8%"
                        }}/>
                    }
                {present  && 
                <div className={classes.TextField} >
                    <div className={classes.projectitle}>
                    IFolio<br/>    
                    </div>
                    <div style={{textAlign:"center", fontStyle: "italic"}}>
                        A platform to share your portfolio to potential employers online <br/>
                    </div>
                   <div style ={{fontSize:10}}>
                    DevTools: Node.js | Express.js | React.js | Redux.js | MaterialUI | MongoDB | CloudinaryCDN | Heroku | Postman
                   </div>
                    <Button component={Link} variant="outlined" to="http://ifolioproject.herokuapp.com" className={classes.button} color="primary"> View Website </Button>
                </div>
                }
            </Grid>
            
           
            <Grid onMouseOver={() => setPresent1(true)} 
                    onMouseLeave={() => setPresent1(false)}
                    style={{backgroundColor: present1 ? "#deede1" : "#deede1" }} item xs={12} sm={6} md={3} lg={3} className={classes.projectgrid} >
                
                {present1 == false &&
                <img src={shopoint} style={{
                        width: "8em",
                        height: "auto",
                        display: "block",
                        margin: "auto",
                        marginTop: "4em",
                        }}/>
                    }
                {present1  && 
                <div className={classes.TextField} style={{marginTop: "2em"}} >
                    <div className={classes.projectitle}>
                    Shopoint<br/>    
                    </div>
                    <div style={{textAlign:"center", fontStyle: "italic"}}>
                        A prototype of e-commerce platform <br/>
                    </div>
                   <div style ={{fontSize:10}}>
                    DevTools: Node.js | Express.js | EJS | Bootstrap | MongoDB | CloudinaryCDN | Heroku | Postman
                   </div>
                    <Button component={Link} variant="outlined" to="http://info30005algor.herokuapp.com" className={classes.button} color="primary"> View Website </Button>
                </div>
                }
            </Grid>

            <Grid onMouseOver={() => setPresent2(true)} 
                    onMouseLeave={() => setPresent2(false)}
                    style={{backgroundColor: present2 ? "#383a48" : "#222534" }} item xs={12} sm={6} md={3} lg={3} className={classes.projectgrid} >
                
                {present2 == false &&
                <img src={malvinnathan} style={{
                        width: "10em",
                        height: "auto",
                        display: "block",
                        margin: "auto",
                        marginTop: "4em",
                        }}/>
                    }
                {present2  && 
                <div className={classes.TextField} style={{color:'white', textAlign: "center",display: "block", justify:'center', alignItems:'center', marginTop: '3em'}} >
                    <div className={classes.projectitle}> malvinnathan<br/>    
                    </div>
                    <div style={{textAlign:"center", fontStyle: "italic"}}>
                        This personal website <br/>
                    </div>
                   <div style ={{fontSize:10}}>
                    DevTools: Gatsby.js | React.js | MaterialUI | EmailJS | Vercel
                   </div>
                    <Button component={Link} variant="outlined" to="http://malvinnathan.com" className={classes.button} style={{color:"white"}} color="primary"> View Website </Button>
                </div>
                }
            </Grid>
            
            <Grid onMouseOver={() => setPresent3(true)} 
                    onMouseLeave={() => setPresent3(false)}
                    style={{backgroundColor: present3 ? "#ffffff" : "#ffffff", border: "solid" }} item xs={12} sm={6} md={3} lg={3} className={classes.projectgrid} >
                
                {present3 == false &&
                <img src={unityimage} style={{
                        width: "8em",
                        height: "auto",
                        display: "block",
                        margin: "auto",
                        marginTop: "3em",
                        }}/>
                    }
                {present3  && 
                <div className={classes.TextField} style={{textAlign: "center",display: "block", justify:'center', alignItems:'center', marginTop: '3em'}} >
                    <div className={classes.projectitle}> Unity Game Development<br/>    
                    </div>
                    <div style={{textAlign:"center", fontStyle: "italic"}}>
                        Coming Soon <br/>
                    </div>
                   <div style ={{fontSize:10}}>
                    DevTools: C# | UnityPro
                   </div>
                    <Button component={Link} variant="outlined" to="/" className={classes.button} color="primary"> View Website </Button>
                </div>
                }
            </Grid>
            <Grid style={{border: "solid" }} item xs={12} sm={6} md={3} lg={3} className={classes.projectgrid} >
            
                <div className={classes.TextField} style={{textAlign: "center",display: "block", justify:'center', alignItems:'center', marginTop: '5em'}} >
                    
                    <div style={{textAlign:"center", fontStyle: "italic"}}>
                        Coming Soon.. <br/>
                    </div>
                
                </div>
                
            </Grid>
            <Grid style={{border: "solid" }} item xs={12} sm={6} md={3} lg={3} className={classes.projectgrid} >
            
                <div className={classes.TextField} style={{textAlign: "center",display: "block", justify:'center', alignItems:'center', marginTop: '5em'}} >
                    
                    <div style={{textAlign:"center", fontStyle: "italic"}}>
                        Coming Soon.. <br/>
                    </div>
                
                </div>
                
            </Grid>
           
        </Grid>
       

        </>
    )
}
